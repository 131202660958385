import { graphql } from "gatsby";
import * as React from "react";
import Layout from "../components/layout/layout";
import { parseStory } from "../components/utils";
import arr from "../resources/assets/link-pointer.svg";
import cv from "../resources/assets/cv.svg";

const GroupMembers = ({ data }) => {
  const { members, collabs } = parseStory(data.members).content;
  console.log(members);
  return (
    <Layout>
      <div className="min-h-full">
        <section className="py-6 md:py-12 as-cont">
          <h1 className="as-h1">Meet the team</h1>
          <div className=" gap-8 mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 pb-2">
            {members.map((m) => (
              <Member m={m} key={m["_uid"]} />
            ))}
          </div>
        </section>
        <section className="py-6 md:py-12 as-cont">
          <h1 className="as-h1">Collaborations</h1>
          <div className="mt-10">
            {collabs.map((c) => (
              <div
                className="mt-6 rounded-xl shadow-lg bg-white p-6"
                key={c["_uid"]}
              >
                <div className="font-bold text-2xl pb-2 border-b w-2/3 mb-4">
                  {c.university}
                </div>
                {c.collabs.map((p) => (
                  <p key={p["_uid"]} className="mt-1">
                    {p.text}
                  </p>
                ))}
              </div>
            ))}
          </div>
        </section>
      </div>
    </Layout>
  );
};

const Member = ({ m }) => (
  <article className="rounded-xl shadow-lg bg-white p-6 col-span-1 sm:first-of-type:col-span-2">
    <div className="flex justify-between items-center gap-4">
      <div className="text-lg font-bold leading-tight">
        <h2>{m.name}</h2>
        <p className="text-indigo-500">{m.title}</p>
      </div>
      <img
        src={m.img.filename}
        alt={m.img.alt ?? ""}
        className="rounded-full w-[90px] h-[90px] object-cover"
      />
    </div>
    <p className="my-6">{m.text}</p>
    <div className="flex justify-end gap-4">
      {m?.usz_website && (
        <a
          href={m.usz_website}
          className="flex items-center gap-0.5 group px-2 py-1 bg-gray-200 rounded-md text-sm"
          rel="noopener noreferrer"
          target="_blank"
        >
          see on USZ website
          <img
            src={arr}
            alt=""
            className="group-hover:translate-x-1 transition-transform w-5"
          />
        </a>
      )}
      {m?.cv?.filename && (
        <a
          href={m?.cv?.filename}
          className="flex items-center gap-0.5 px-2 py-1 bg-gray-200 rounded-md text-sm"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img src={cv} alt="" className="w-5" />
          download cv
        </a>
      )}
      <a
        href={m.publications}
        className="flex items-center gap-0.5 group px-2 py-1 bg-gray-200 rounded-md text-sm"
        rel="noopener noreferrer"
        target="_blank"
      >
        see publications
        <img
          src={arr}
          alt=""
          className="group-hover:translate-x-1 transition-transform w-5"
        />
      </a>
    </div>
  </article>
);

export const query = graphql`
  query {
    members: storyblokEntry(slug: { eq: "team-members" }) {
      content
    }
  }
`;

export default GroupMembers;
